<template>
  <div  class="ma-md-5 mb-16 px-0 " >

    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">我的优惠券</div>
    </div>

    <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            名称
          </th>
          <th class="text-left">
            面值
          </th>
          <th class="text-left">
            过期时间
          </th>
        </tr>
      </thead>
      <tbody  v-if="coupons.length>0">
        <tr
          v-for="item in coupons"
          :key="item.coupon_id"
        >
          <td>{{ item.coupon_name }}</td>
          <td>{{ item.money }}</td>
          <td>{{ item.end_time }}</td>
        </tr>
      </tbody>
      <tbody  v-if="coupons.length==0">
        还没有任何优惠券~
      </tbody>
    </template>
  </v-simple-table>

  </div>
</template>

<script>

import { mapState } from 'vuex'

import userApi from '@/api/user'

export default {


  data: () => ({
    coupons:[]
    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.domain,
    }),
  },
  created() {

    userApi.coupon().then(res => {
      //console.log(res)
      this.coupons = res.data
      
      

    })
    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.goods-item {
  cursor: pointer;
}



</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .categorybanner .v-image__image--cover
      background-position : center

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .categorybanner .v-image__image--cover
      background-position : 17% !important
  
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000000

</style>